// ConfigContext.js

import { createContext, useContext, useState, useEffect } from 'react';
import { API_URL } from '../pages/coursecoConsultar/serviceWorker';

const ConfigContext = createContext();

export const ApiConfigProvider = ({ children }) => {
    const [apiConfig, setApiConfig] = useState(null);
    useEffect(() => {
      const fetchApiConfig = async () => {
        try {
          const response = await fetch(API_URL + 'settings/list');
          const resp = await response.json();
          setApiConfig(resp.data.settings);
        } catch (error) {
          console.error('Error fetching API config:', error);
        }
      };
  
      fetchApiConfig();
    }, []);
  return (
    <ConfigContext.Provider value={apiConfig}>
      {children}
    </ConfigContext.Provider>
  );
};

export const useApiConfig = () => {
  return useContext(ConfigContext);
};
