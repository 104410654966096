import { createSlice } from "@reduxjs/toolkit";

export const newsSlice = createSlice({
  name: "news",
  initialState: {
    articles: [],
    article: {},
  },
  reducers: {
    add: (state, action) => {
      state.articles = [...action.payload.items];
    },
    add_article: (state, action) => {
      state.article = {...action.payload};
    },
  },
});

// Action creators are generated for each case reducer function
export const { add } = newsSlice.actions;
export const { add_article } = newsSlice.actions;

export default newsSlice.reducer;
